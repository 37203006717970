<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">

          <h3 class="fw-bolder m-0">Lot Shipping History by Order ID</h3>

        </div>

      </div>
      <div class="card-body p-7">

        <div class="mb-3">

          <label class="col-form-label fw-bold fs-6">Order ID</label>

          <input type="number" class="form-control" :class="{'is-invalid' : errors.value == true}" v-model="order_id">

          <span class="text-danger" v-if="errors.value == true">Invalid Order ID</span>

        </div>

        <div class="row">

          <div class="col-12">

            <button
              type="submit"
              ref="submitButton"
              class="btn btn-lg btn-primary fw-bolder ms-auto d-block my-2"
              @click="runReport"
            >
              <span class="indicator-label">
                Run Report
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/ConsumerOrderEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
export default {
  name: 'LotShippingHistoryByOrderId',
  setup(){

    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });

    const order_id = ref(null);

    const runReport = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(ReportActions.LOT_SHIPPING_HISTORY,{
          searchBy : {
          type : 'orderId',
          value : order_id.value
        }
      }).then((response) => {

        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = true;

        submitButton.value?.removeAttribute("data-kt-indicator")

      })

    }

    onMounted(() => {

      setCurrentPageBreadcrumbs("Reports",[

        {title : "Lot Shipping History by Order ID",to : "/reports/lot-shipping-history-by-order-id"}

      ]);

    });

    return {
      store,
      order_id,
      runReport,
      submitButton,
      errors
    }
  }
}
</script>
